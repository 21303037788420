'use strict';

const appendToUrl = require('../util/appendToUrl');
var ReserveAndGo = require('../components/reserveAndGo');

// Reusable fa
let preferredShopId;

const googleHelper = require('../../js/components/googleHelper');

/**
 * Search stores and show result
 * @param {Object} storeData storeData
 */
function showStoresResult(storeData) {
    $('#jquery-modal-results-noresults').hide();
    var $resultsDiv = $('#jquery-modal-results-stores');
    $resultsDiv.empty();
    if (
        storeData.stores &&
        storeData.stores.length > 0 &&
        storeData.storesResultsHtmlModal
    ) {
        $resultsDiv.append(storeData.storesResultsHtmlModal);
        const favStoreCheckbox = $('#favoriteStore-' + preferredShopId);
        if (favStoreCheckbox) {
            $('#favoriteStore-' + preferredShopId).attr(
                'checked',
                true
            );
            $('#favoriteStore-' + preferredShopId).attr(
                'disabled',
                true
            );
        }
    } else {
        $('#jquery-modal-results-noresults').show();
    }
}

module.exports = {
    init: function () {
        $('#jquery-modal-results-noresults').hide();
    },
    setSelectedStore: function () {
        $(document).on('click', '.js-btn-pickhere', function () {
            const selectedStoreId = $(this).data('store-id');
            let setSelectedStoreUrl = $('#modalPreferredStore').data(
                'set-selected-store-id-url'
            );
            let setSelectedStoreUrlParams = {
                selectedStoreId: selectedStoreId
            };
            setSelectedStoreUrl = appendToUrl(
                setSelectedStoreUrl,
                setSelectedStoreUrlParams
            );
            $.ajax({
                url: setSelectedStoreUrl,
                type: 'post',
                success: function (setData) {
                    $('.js-container-storeDelivery').html(setData.html);
                    const source = $('.product-detail').length > 0 ? 'pdp' : '';
                    if (source === 'pdp') {
                        ReserveAndGo.setSelectedStorePDPInfo(selectedStoreId);
                    } else {
                        location.reload();
                    }
                }
            });

            // Fill shipping form
            var form = $('form.shipping-form');
            var attrs = $(this)[0].dataset;
            var element;

            Object.keys(attrs).forEach(function (attr) {
                element = attr === 'countryCode' ? 'country' : attr;
                $('[name$=' + element + ']', form).val(attrs[attr]);
            });
        });
    },
    searchStores: function () {
        $('#jquery-modal-preferredstore-form').submit(function (e) {
            e.preventDefault();
            var $form = $(this);
            var options = {
                apothecary: true
            };

            googleHelper.searchStores($form, options, (content) => {
                showStoresResult(content);
            });
        });
    },
    detectLocation: function () {
        $('.jquery-detect-location-storeDelivery').on('click', function () {
            var $form = $('#jquery-modal-preferredstore-form');
            var options = {
                apothecary: true
            };

            googleHelper.detectStores($form, options, (content) => {
                showStoresResult(content);
            });
        });
    }
};
